import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { AppBar, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { jsPDF } from "jspdf"

import PaperArialRegular from "fonts/PaperArialRegular"
import PaperArialBold from "fonts/PaperArialBold"
import useSharedReportData from "model/documents/reports/useSharedReportData"
import DocumentReportView from "components/views/documents/DocumentReportView"
import LoadingModal from "components/views/generic/LoadingModal"

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}))

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(4),
  alignSelf: "center",
  width: "90%",
  maxWidth: "816px",
  fontSize: "100%",
  [theme.breakpoints.down("md")]: { fontSize: "4vmin" },
}))

const PdfSharedPage = ({ teamKey }: { teamKey: string }) => {
  const { sharedId } = useParams()
  const localGeneration = useLocalGeneration()
  const disableOpenTracking = useDisableOpenTracking()
  const reportData = useSharedReportData({ teamKey, sharedId })
  const documentReportRef = useRef<HTMLDivElement>(null)
  const [isCreatingPdf, setIsCreatingPdf] = useState(false)
  const [isReportLoaded, setIsReportLoaded] = useState(false)
  const pdfFilename = reportData?.filename || `${sharedId}.pdf`
  const pdfDocumentUrl = `/api/v1/shared/teams/${teamKey}/workflows/${sharedId}.pdf`
  const openTrackingUrl = `/api/v1/shared/teams/${teamKey}/workflows/${sharedId}/opened`
  const onDownloadPdf = useCallback(() => {
    if (!localGeneration) {
      window.location.href = pdfDocumentUrl
    } else if (documentReportRef.current) {
      setIsCreatingPdf(true)
      createPdfFromHtml(documentReportRef.current, pdfFilename, () => {
        setIsCreatingPdf(false)
      })
    }
  }, [localGeneration, pdfDocumentUrl, pdfFilename])
  const onReportLoaded = useCallback(
    () => setIsReportLoaded(true),
    [setIsReportLoaded]
  )
  useEffect(() => {
    if (!disableOpenTracking) {
      fetch(openTrackingUrl, { method: "POST" })
    }
  }, [openTrackingUrl, disableOpenTracking])
  useEffect(() => {
    const viewport = document.getElementById("viewport") as HTMLMetaElement
    const originalContent = viewport.getAttribute("content") || ""
    viewport.setAttribute("content", "width=816")
    return () => {
      viewport.setAttribute("content", originalContent)
    }
  }, [])
  return (
    <div>
      <StyledAppBar
        position="sticky"
        elevation={0}
        sx={{ displayPrint: "none" }}
        style={{
          transition: "opacity 0.5s",
          opacity: isReportLoaded ? 1 : 0,
        }}
      >
        <StyledButton
          size={"large"}
          variant="contained"
          color="primary"
          disabled={!isReportLoaded}
          onClick={onDownloadPdf}
        >
          View & Print PDF
        </StyledButton>
      </StyledAppBar>
      <div ref={documentReportRef}>
        <DocumentReportView
          onReportLoaded={onReportLoaded}
          reportData={reportData}
        />
      </div>
      <LoadingModal open={isCreatingPdf} />
    </div>
  )
}

const useDisableOpenTracking = (): boolean => {
  const { search } = useLocation()
  return React.useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get("disableOpenTracking") === "true"
  }, [search])
}

const useLocalGeneration = (): boolean => {
  const { search } = useLocation()
  return React.useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get("local") === "true"
  }, [search])
}

const createPdfFromHtml = (
  htmlElement: HTMLDivElement,
  filename: string,
  completion: () => void
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "letter",
    unit: "px",
    hotfixes: ["px_scaling"],
    putOnlyUsedFonts: true,
    compress: true,
  })
  doc.addFileToVFS("PaperArialRegular.ttf", PaperArialRegular.base64)
  doc.addFont("PaperArialRegular.ttf", "PaperArial", "normal")
  doc.addFileToVFS("PaperArialBold.ttf", PaperArialBold.base64)
  doc.addFont("PaperArialBold.ttf", "PaperArial", "bold")
  doc.setFont("PaperArial")
  doc.html(htmlElement, {
    margin: 0,
    x: 0,
    y: 0,
    width: 816,
    windowWidth: 816,
    html2canvas: {
      ignoreElements: (element: HTMLElement) => {
        return element.classList.contains("exclude-from-pdf")
      },
      backgroundColor: "#ffffff",
    },
    callback: async (doc) => {
      doc.save(filename, { returnPromise: true }).then(completion)
    },
  })
}

export default PdfSharedPage
